<template>
    <div>
        <div class="card st-framework-card">

            <div class="card-header">
                <table style="width: 100%;">
                    <tr>
                        <td style="padding: 0 0 0 1rem; width: 280px;">
                            <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                            {{ $i18n.tc('translations.' + name, 1).toUpperCase() }}
                        </td>
                        <template v-if="serverDataLoaded">
                            <td style="width: 1rem;"></td>
                            <td style="padding: 0 10px; border: solid 1px lightgray; width: 30%;">
                                {{ linkedToolData.phase.label }}
                            </td>
                            <td style="width: 1rem;"/>
                            <td style="padding: 0 10px; border: solid 1px lightgray;">
                                <LinkAction :action="actions.workPlanLinkAction"/>
                            </td>
                            <td style="padding: 0; width: 90px; text-align: right;">(#{{ linkedToolData.phase.id }})</td>
                            <td style="width: 1rem;"></td>
                        </template>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0">
                <template v-if="serverDataLoaded">
                    <Table :key="'assembly-table-'+reloadTableComponent" header-class="light" footer-class="light" body-class=""
                           with-filter with-pagination no-state-loading autofocus :lines-per-page=crudLinesPerPage
                           :reload-table-data="reloadTableData"
                           :rows="filteredComponentList"
                           :fields="fields.table" :actions="actions.table" :state="state"
                           v-on:clear-selection="clearSelection"
                           v-on:change-number-assembled="changeNumberAssembled"
                           v-on:refresh="getComponentInstances"
                           v-on:select-all="selectAll"
                           v-on:select-filter="selectFilter"
                           v-on:zoom-component="zoomComponent"
                           v-on:zoom-reset="zoomReset"/>
                </template>
                <template v-else-if="toolDataLoaded && !linkedToolData.phase">
                    <div class="card">
                        <div class="card-body">
                            <table class="table table-borderless m-0">
                                <tr>
                                    <td style="width: 100px; vertical-align: middle;">
                                        {{ fields.selectProjectField.label }}
                                    </td>
                                    <td>
                                        <SelectField :field="fields.selectProjectField" :data="linkedToolData" v-on:input="selectProject"/>
                                    </td>
                                </tr>
                                <tr v-if="linkedToolData.project">
                                    <td style="width: 100px; vertical-align: middle;">
                                        {{ fields.selectOrderField.label }}
                                    </td>
                                    <td>
                                        <SelectField :key="'order-'+(linkedToolData.project ? linkedToolData.project.id : 0)" :field="fields.selectOrderField" :data="linkedToolData" v-on:input="selectOrder"/>
                                    </td>
                                </tr>
                                <tr v-if="linkedToolData.order">
                                    <td style="vertical-align: middle;">
                                        {{ fields.selectPhaseField.label }}
                                    </td>
                                    <td>
                                        <SelectField :key="'phase-'+(linkedToolData.order ? linkedToolData.order.id : 0)" :field="fields.selectPhaseField" :data="linkedToolData" v-on:input="selectPhase"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import LinkAction from "@/components/actions/LinkAction.vue";
import SelectField from "@/components/forms/fields/SelectField.vue";
import Table from "@/components/Table.vue";

/* global sketchup:false */

export default {
    name: 'Assembly',
    components: {
        LinkAction,
        SelectField,
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    data() {
        return {
            toolDataLoaded: false,
            serverDataLoaded: false,
            sketchUpToolData: null,
            linkedToolData: null,
            fields: null,
            actions: null,
            settings: null,
            crudLinesPerPage: 0,
            filterChoice: null,
            processedComponentList: [],
            filteredComponentList: [],
            assemblyData: [],
            reloadTableComponent: 0,
            reloadTableData: 0,
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        changeNumberAssembled(data) {
            this.state.loading = true;
            this.state.disabled = true;
            let changedComponent = this.processedComponentList.find(component => {
                return component.id === data.id;
            });
            let changedDefinition = changedComponent.definition;

            let phaseIndex = this.assemblyData.findIndex(component => {
                return component.definition === changedDefinition;
            });
            if (phaseIndex < 0) {
                this.assemblyData.push({
                    definition: changedDefinition,
                    number_assembled: data.number_assembled,
                });
            } else {
                this.assemblyData[phaseIndex].number_assembled = data.number_assembled;
            }

            this.$http.put('/managed_orders/update_assembly_data/' + this.linkedToolData.phase.id, {
                assembly_data: this.assemblyData,
            }, {}).then(() => {
                this.processedComponentList.forEach(component => {
                    if (component.definition === changedDefinition) {
                        component.number_assembled = data.number_assembled;
                        component.to_assemble = component.instances_in_selection - component.number_assembled;
                        if (component.to_assemble === 0) {
                            component.to_assemble_style = 'background: #28a745; color: white;';
                        } else if (component.to_assemble < 0) {
                            component.to_assemble_style = 'background: red; color: white;';
                        } else {
                            delete component.to_assemble_style;
                        }
                    }
                });
                this.filterComponentList();
                this.state.disabled = false;
                this.state.loading = false;
            }).catch((error) => {
                console.log("Assembly:changeNumberAssembled():error:", error);
            });
        },
        clearSelection() {
            sketchup.clearSelection();
        },
        fetchData() {
            this.$http.get(this.api + '/manage_assembly/' + this.sketchUpToolData.phase_id, {}).then((res) => {
                this.fields = res.data.fields;
                this.actions = res.data.actions;
                this.linkedToolData = res.data.tool_data;
                this.assemblyData = res.data.assembly_data ? res.data.assembly_data : [];
                this.settings = res.data.settings;
                this.crudLinesPerPage = res.data.crudLinesPerPage;
                this.serverDataLoaded = true;
                sketchup.getComponentInstances();
            }).catch((error) => {
                console.log("Assembly:fetchData():error:", error);
                sketchup.setToolData(0, 0, 0);
            });
        },
        fetchToolData() {
            this.$http.get(this.api + '/get_tool_data/' + this.sketchUpToolData.project_id + '/' + this.sketchUpToolData.order_id + '/' + this.sketchUpToolData.phase_id, {}).then((res) => {
                this.fields = res.data.fields;
                this.linkedToolData = res.data.tool_data;
                this.toolDataLoaded = true;
                this.state.loading = false;
            }).catch((error) => {
                console.log("Assembly:fetchToolData():error:", error);
            });
        },
        filterComponentList() {
            this.filteredComponentList = this.processedComponentList.filter(processedComponent =>
                (this.filterChoice !== 'to_assemble' || processedComponent.to_assemble !== 0)
            );
            this.reloadTableData++;
        },
        getComponentInstances() {
            this.state.loading = true;
            sketchup.getComponentInstances();
        },
        getComponentInstancesCallback(entities) {
            this.$worker.run((rawComponentList, assemblyDefinitions, assemblyData) => {
                let newProcessedComponentList = [];

                rawComponentList.forEach(component => {
                    if (!assemblyDefinitions.includes(component.definition.substring(0, component.definition.lastIndexOf("#")))) {
                        return
                    }

                    let phaseComponent = assemblyData.find(assemblyComponent => {
                        return assemblyComponent.definition === component.definition
                    });

                    let i = newProcessedComponentList.findIndex(item =>
                        (item.definition === component.definition) &&
                        ((phaseComponent && phaseComponent.ignore_flipped === 1) || (item.is_flipped === component.is_flipped))
                    );
                    if (i === -1) {
                        let j = newProcessedComponentList.findIndex(item =>
                            (item.definition === component.definition) &&
                            (item.is_flipped === (component.is_flipped === 1 ? 0 : 1))
                        );

                        let newComponent = {
                            id: newProcessedComponentList.length + 1,
                            entity_ids: [component.entity_id],
                            instances: [component.entity_id],
                            definition: component.definition,
                            instances_in_model: component.instances_in_model,
                            component_color: {
                                value: component.component_color,
                                label: component.component_color_name,
                            },
                            is_flipped: component.is_flipped,
                            components_in_selection: 1,
                            instances_in_selection: 1,
                            hide_make_unique: true,
                            number_assembled: phaseComponent ? phaseComponent.number_assembled : 0,
                        }

                        if (j === -1) {
                            newComponent.hide_make_unique = true;
                        } else {
                            newProcessedComponentList[j].hide_make_unique = false;
                            if (newProcessedComponentList[j].is_flipped === 1) {
                                newProcessedComponentList[j].flipped_style = 'background: red; color: white;';
                            }
                            newComponent.hide_make_unique = false;
                            if (newComponent.is_flipped === 1) {
                                newComponent.flipped_style = 'background: red; color: white;';
                            }
                        }

                        newProcessedComponentList.push(newComponent);
                    } else {
                        if (newProcessedComponentList[i].entity_ids.indexOf(component.entity_id) === -1) {
                            newProcessedComponentList[i].entity_ids.push(component.entity_id);
                            newProcessedComponentList[i].components_in_selection++;
                        }
                        newProcessedComponentList[i].instances.push(component.entity_id);
                        newProcessedComponentList[i].instances_in_selection++;
                    }
                });

                newProcessedComponentList.forEach(component => {
                    component.to_assemble = component.instances_in_selection - component.number_assembled;
                    if (component.to_assemble === 0) {
                        component.to_assemble_style = 'background: #28a745; color: white;';
                    } else if (component.to_assemble < 0) {
                        component.to_assemble_style = 'background: red; color: white;';
                    } else {
                        delete component.to_assemble_style;
                    }
                });

                newProcessedComponentList.sort(function (a, b) {
                    let idxA = a.definition.lastIndexOf('#');
                    let idxB = b.definition.lastIndexOf('#');
                    if (idxA > -1 && idxB > -1 && a.definition.substring(0, idxA) === b.definition.substring(0, idxB)) {
                        if (parseInt(a.definition.substring(idxA + 1)) < parseInt(b.definition.substring(idxB + 1))) return -1;
                        if (parseInt(a.definition.substring(idxA + 1)) > parseInt(b.definition.substring(idxB + 1))) return 1;
                    } else {
                        if (a.definition < b.definition) return -1;
                        if (a.definition > b.definition) return 1;
                    }
                    if (a.is_flipped < b.is_flipped) return -1;
                    if (a.is_flipped > b.is_flipped) return 1;
                    return 0;
                });

                return newProcessedComponentList;
            }, [JSON.parse(entities), this.settings.assembly_definitions, this.assemblyData]).then(data => {
                this.processedComponentList = data;
                this.filterComponentList();
                this.reloadTableComponent++;
                this.state.loading = false;
            }).catch(error => {
                console.log("Assembly:getComponentInstancesCallback():error:", error);
            });
        },
        getToolDataCallback(sketchUpToolData) {
            this.sketchUpToolData = sketchUpToolData;
            if (sketchUpToolData.phase_id) {
                this.fetchData();
            } else {
                this.fetchToolData();
            }
        },
        selectAll(data) {
            let ids = [];
            this.filteredComponentList.forEach(component => {
                if (data.ids.includes(component.id)) {
                    ids = ids.concat(component.entity_ids);
                }
            });
            sketchup.selectComponents(ids);
        },
        selectFilter(choice) {
            this.filterChoice = choice.value;
            this.filterComponentList();
            this.reloadTableComponent++;
        },
        selectOrder() {
            this.fields.selectPhaseField.api = '/managed_orders/phases/order/' + this.linkedToolData.order.id;
            this.linkedToolData.phase = null;
        },
        selectPhase() {
            this.state.loading = true;
            sketchup.setToolData(this.linkedToolData.project.id, this.linkedToolData.order.id, this.linkedToolData.phase.id);
        },
        selectProject() {
            this.fields.selectOrderField.api = '/orders/project/' + this.linkedToolData.project.id;
            this.linkedToolData.order = null;
            this.linkedToolData.phase = null;
        },
        setToolDataCallback(sketchUpToolData) {
            this.sketchUpToolData = sketchUpToolData;
            if (sketchUpToolData.phase_id) {
                this.fetchData();
            } else {
                this.fetchToolData();
            }
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            sketchup.layer(this.settings.assembly_tag, true);
            sketchup.zoomExtents(component.entity_ids[0]);
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.layer(this.settings.assembly_tag, false);
            sketchup.zoomExtents();
        }
    }
}
</script>

<style scoped>
>>> input::-webkit-outer-spin-button,
>>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

>>> input[type=number] {
    -moz-appearance: textfield;
}
</style>